import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-1b08b2d4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "cus-button"
};
export default {
  __name: 'CusButton',
  props: {
    text: {
      type: String,
      "default": "免费使用"
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", null, _toDisplayString(__props.text), 1)]);
    };
  }
};