//引入封装好的 service
import service from './axios';
 
//声明一个基础接口变量
//let baseURL = "http://vip.netcaa.com/api/";
//let baseURL = "/api";
// //配置开发环境
// if (process.env.NODE_ENV === 'development') {
//     baseURL = '';
// }
 
// // 配置生产环境
// if (process.env.NODE_ENV === 'production') {
//   baseURL = '';
// }
 
//设置请求头（如果请求头统一的话可以在axios文件设置，则无须从这里传过去）
// const header = {
//   Accept: 'application/json;charset=UTF-8',
// }
// const baseURL = "http://www.1qiyu.com/api";
let request = {
    wxLogin(params) {
        return service({
            url: '/wxLogin',
            method: 'get',
            params
        })
    },
    //登录
    login(data) {
        return service({
            url: '/wxRegister',
            method: 'post',
            data
        })
    },
    //注册
    register (data) {
        return service({
            url: '/system/user/registerAndBindingWx',
            method: 'post',
            data
        })
    },
    //短信验证码
    registerCode (data) {
        return service({
            url: '/system/user/registerCode',
            method: 'post',
            data
        })
    },
    //图片验证码
    captchaImage () {
        return service({
            url: '/captchaImage',
            method: 'get',
        })
    },
}
export default request