/*
 * @Author: dinghao dinghao361@gmail.com
 * @Date: 2023-07-21 18:08:20
 * @LastEditors: dinghao dinghao361@gmail.com
 * @LastEditTime: 2023-08-03 21:22:23
 * @FilePath: /qiyu-official-website-wechat-vue/src/request/axios.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from "axios";
import { showNotify } from 'vant';
const service = axios.create({
  timeout: 30000,//数据响应过期时间
  baseURL: "https://m.iqiyu.net/api"//'http://vip.netcaa.com/api',
})
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
//请求拦截器
service.interceptors.request.use((config) => {
   //是否需要设置 token
//    const isToken = (config.headers || {}).isToken === false;
//    console.log(getToken())
//    if (getToken() && !isToken) {
//      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
//    }
  return config
}, (error) => {
  //对请求错误做点什么
  return error
})
 
//响应拦截器
service.interceptors.response.use((res) => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg;
    if(code === 200){
        return res.data;
    } else {
        showNotify({ type: 'danger', message: msg});
        return;
        // if (code === 401) {
        //     showNotify({ type: 'danger', message: '登录状态已过期，请重新登录'});
        //     return
        // } else if (code === 500) {
        //     showNotify({ type: 'danger', message: msg});
        //   return;
        // } else if (code !== 200) {
        //     showNotify({ type: 'danger', message: msg});
        //   return;
        // }
    }
})
export default service