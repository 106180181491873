import { createRouter, createWebHistory } from 'vue-router'
import wxLogin from '@/views/register/wx-login.vue'
import wxRegister from '@/views/register/wx-register.vue'

const routes = [
  {
    path: '/',
    name: 'wx-login',
    component: wxLogin,
    meta:{
        title: '绑定'
    }
  },
  {
    path: '/wxRegister',
    name: 'wxRegister',
    component: wxRegister,
    meta:{
        title: '注册'
    }
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
    // history: createWebHashHistory(process.env.BASE_URL),
    routes
})
console.log("process.env.BASE_URL"+process.env.BASE_URL+process.env.NODE_ENV)
router.beforeEach((to, from, next) => {
    console.log(from)
    if (to.meta.title) {
        document.title = to.meta.title || "绑定"
    }
    next();
})
export default router
