import {showToast, showLoadingToast, closeToast } from 'vant';
// import 'vant/es/toast/style';
let $toast = {
    loadingToast: (message = '加载中...') => {
        showLoadingToast({
            duration: 0,
            message,
            forbidClick: true,
            loadingType: 'spinner',
        });
    },
    toast: (message) => {
        showToast(message);
    },
    closeToast:() => {
        closeToast();
    }
}
export default $toast